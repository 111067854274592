import React from "react";
import style from "./WeakStreakLevels.module.scss";
interface GameLevelIconProps {
    level: any;
    metadata: any;
}

const WeekStreakLevels = ({ level = 0, metadata }: GameLevelIconProps) => {
    // level=0
    return (
        <div className={style["levelGroupWrapper"] + ' weekstreakLevelGroupWrapper'}>
            <div className={style["icon"]}>
                <img
                    src={
                        metadata?.value?.icon
                            ? metadata?.value?.icon?.replace(
                                  ".png",
                                  "_256x256.png"
                              )
                            : "https://storage.googleapis.com/us-cdn-aviron/achievements/iconAchievement364_256x256.png"
                    }
                    alt="img"
                />
            </div>
            <div className={style["levelTextWrapper"]}>
                <div
                    className={
                        "levelText" + `${level > 99 ? " levelTextLong" : ""}`
                    }
                    style={{
                        color: metadata?.font_color || "white",
                    }}
                >
                    {level > 999 ? "999+" : level}
                </div>
            </div>
        </div>
    );
};

export default WeekStreakLevels;
