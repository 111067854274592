import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Box,
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";
import GoBack from "Components/GoBack";
import PauseMembershipModal from "../PauseMembership";
import Swal from "sweetalert2";
import useQueryParams from "Hooks/useQueryParams";
import { useHistory } from "react-router-dom";
import {
    useCancelUserSubscriptionMutation,
    useCheckCancelOfferAvaiableSubscriptionQuery,
    usePausePaymentSubscriptionMutation,
} from "services/webServer";
import { CONSTANTS } from "consts";
import { AvironLoading } from "Components/Icons/AvironLoading";
import MembershipCanceledModal, {
    IMembershipCanceledModalRef,
} from "./MembershipCanceledModal";
import {
    useCheckCanPauseMembership,
    useGetOptionListCancelReasons,
    useGetSubscriptionData,
} from "./CancelReasons.hooks";
import { useTrackingData } from "Components/TrackingData";
import { checkIsUpgradeSubscription } from "utils/handleCheckUpgradeSubscription";

const MAX_LENGTH_REASON = 255;

enum SUBSCRIPTION_TYPE {
    MONTHLY = "monthly",
    ANNUAL = "yearly",
}

const CancelReasons = () => {
    const history = useHistory();
    const { id = "" } = useQueryParams();
    const membershipCancelSuccessRef =
        useRef<IMembershipCanceledModalRef>(null);

    const {
        subscriptionData,
        isLoadingSubscription,
        error: errorSubscriptionData,
    } = useGetSubscriptionData(id);
    const [pausePaymentSubscription] = usePausePaymentSubscriptionMutation({
        onError: async () => {
            await Swal.fire(
                "Pause membership error",
                "Pause membership failed",
                "error"
            );
            history.replace(CONSTANTS.NAVIGATION_URL.MEMBERSHIPS);
        },
        onCompleted: async () => {
            await Swal.fire(
                "Success",
                "Membership paused successfully.",
                "success"
            );
            history.replace(CONSTANTS.NAVIGATION_URL.MEMBERSHIPS);
        },
    });

    const { checkCanPauseData, isLoadingCheckCanPauseSubscription } =
        useCheckCanPauseMembership(id);

    const {
        trackingFeedbackScreenView,
        trackingPauseInsteadClick,
        trackingContinueCancelingClick,
        trackingNotCancelClick,
    } = useTrackingData(id);

    const [openPauseModal, setOpenPauseModal] = useState<boolean>(false);
    const [active, setActive] = useState<number>(0);
    const [reason, setReason] = useState<string>("");
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const { data: checkCancelOfferData, loading } =
        useCheckCancelOfferAvaiableSubscriptionQuery({
            fetchPolicy: "no-cache",
        });

    const isReceivedCancelOffer = useMemo(
        () => !checkCancelOfferData?.checkCancelOfferAvailableSubscription,
        [checkCancelOfferData]
    );

    const isCanPauseSubscription = useMemo(
        () => checkCanPauseData,
        [checkCanPauseData]
    );

    const { data } = useGetOptionListCancelReasons({
        setOpenPauseModal,
        isSubmitted,
        reason,
        setReason,
    });

    const handlePauseSubscription = async ({
        resumesAt,
        reason,
        note,
    }: {
        resumesAt: string;
        reason: string;
        note: string;
    }) => {
        if (!id) return;
        try {
            setOpenPauseModal(false);
            Swal.showLoading();
            await pausePaymentSubscription({
                variables: {
                    id,
                    behavior: "void",
                    resumesAt,
                    reason,
                    note,
                },
            });
        } catch (error) {
            console.log("Pause Subscription ERROR", error);
        }
    };


    const handleContinueCancel = async () => {
        try {
            setIsSubmitted(true);
            // If that reason has input below, the user have to input the reason before clicking button "CONTINUE CANCELING"
            if (
                ([data[2].id, data[7].id].includes(active) &&
                    Boolean(!reason)) ||
                reason.length > MAX_LENGTH_REASON
            ) {
                return;
            }

            const reasonValue = data.find((item) => item.id === active);

            trackingContinueCancelingClick(
                subscriptionData,
                reason ? reason : reasonValue?.title || "Other"
            );

            sessionStorage.setItem(
                "cancelReason",
                JSON.stringify({
                    reason:
                        data.find((item) => item.id === active)?.value ||
                        "other",
                    reasonDetail: reason,
                })
            );


            const subscriptionMachine = subscriptionData?.avironSubscription?.plan?.maxMachines || 1;
            const isAllowRedeemOffer = Boolean(!checkIsUpgradeSubscription(subscriptionMachine)) && Boolean(!isReceivedCancelOffer);

            if (isAllowRedeemOffer) {
                history.push(
                    `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_CANCELLATION_OFFER}?id=${id}`
                );
                return;
            }

            history.push(
                `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_REVIEW_AND_CANCEL}?id=${id}&entry=feedback_screen_view`
            );
        } catch (error) {
            await Swal.fire(
                "error",
                "Something went wrong, please try again",
                "error"
            );
        }
    };

    const handleRenderErrorContent = () => {
        return (
            <>
                {Swal.fire("Error", "Something went wrong", "error").then(
                    (result) => {
                        if (result.isConfirmed) {
                            history.replace(
                                CONSTANTS.NAVIGATION_URL.MEMBERSHIPS
                            );
                        }
                    }
                )}
                ;
            </>
        );
    };

    useEffect(() => {
        if (subscriptionData?.avironSubscription) {
            trackingFeedbackScreenView(subscriptionData);
        }
    }, [subscriptionData]);

    const isAnnualSubscription = useMemo(() => {
        return (
            subscriptionData?.avironSubscription?.plan?.interval ===
            SUBSCRIPTION_TYPE.ANNUAL
        );
    }, [subscriptionData]);

    const isMonthlySubscription = useMemo(() => {
        return (
            subscriptionData?.avironSubscription?.plan?.interval ===
            SUBSCRIPTION_TYPE.MONTHLY
        );
    }, [subscriptionData]);

    if (
        loading ||
        isLoadingSubscription ||
        isLoadingCheckCanPauseSubscription
    ) {
        return <AvironLoading />;
    }

    if (Boolean(!id) || Boolean(errorSubscriptionData)) {
        handleRenderErrorContent();
    }

    return (
        <Box
            sx={{
                marginTop: {
                    xs: "24px",
                    md: "0",
                },
                marginBottom: {
                    xs: "24px",
                    md: "0",
                },
                paddingLeft: {
                    xs: "12px",
                    md: "0",
                },
                paddingRight: {
                    xs: "12px",
                    md: "0",
                },
            }}
        >
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Membership Cancel Reasons | Aviron</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>

                <GoBack />
                <Box
                    sx={{
                        padding: {
                            xs: "16px 12px",
                            md: "40px",
                            backgroundColor: "#fff",
                            marginTop: "24px",
                        },
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: {
                                xs: "18px",
                                sm: "20px",
                            },
                            lineHeight: "20px",
                            letterSpacing: "0.01em",
                            marginBottom: {
                                xs: "8px",
                                md: "12px",
                            },
                            fontFamily: "GRIFTER",
                        }}
                    >
                        Why Are You Canceling?
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            marginBottom: {
                                xs: "32px",
                                md: "40px",
                            },
                        }}
                    >
                        Please choose the reason you're canceling. Your response
                        is confidential.
                    </Typography>
                    <Box>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            {data.map((item) => (
                                <Box
                                    key={item.id}
                                    sx={{
                                        marginBottom: {
                                            xs: "24px",
                                            md: "20px",
                                        },
                                    }}
                                >
                                    <FormControlLabel
                                        key={item.id}
                                        value={item.id}
                                        control={
                                            <Box>
                                                <Radio
                                                    checked={item.id === active}
                                                    sx={{
                                                        "&, &.Mui-checked": {
                                                            color: "#ff4106",
                                                        },
                                                        "& .MuiSvgIcon-root": {
                                                            fontSize: 24,
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        setActive(item.id);
                                                        setReason("");
                                                        setIsSubmitted(false);
                                                    }}
                                                />
                                            </Box>
                                        }
                                        label={item.title}
                                    />
                                    {active === item.id && (
                                        <Box
                                            sx={{
                                                backgroundColor:
                                                    item?.isHasBackground
                                                        ? "#f4f4f4"
                                                        : "#fff",
                                                height: "auto",
                                            }}
                                        >
                                            {item.optionList.map((option) => {
                                                const isHidePauseOptionWithAnnualSubscription =
                                                    (isAnnualSubscription ||
                                                        (Boolean(
                                                            !isCanPauseSubscription
                                                        ) &&
                                                            isMonthlySubscription)) &&
                                                    option.isHideWithAnnualSubscription;

                                                if (
                                                    isHidePauseOptionWithAnnualSubscription
                                                )
                                                    return;
                                                return (
                                                    <Box
                                                        key={option.id}
                                                        sx={{
                                                            padding:
                                                                "10px 12px",
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                fontWeight: 700,
                                                                fontSize: {
                                                                    xs: "18px",
                                                                    sm: "20px",
                                                                },
                                                                lineHeight:
                                                                    "20px",
                                                                letterSpacing:
                                                                    "0.01em",
                                                            }}
                                                        >
                                                            {option.title}
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </RadioGroup>
                    </Box>
                    <Box
                        sx={{
                            textAlign: "right",
                            marginTop: {
                                xs: "12px",
                                md: "20px",
                            },
                        }}
                    >
                        {isAnnualSubscription ||
                        (isMonthlySubscription &&
                            Boolean(!isCanPauseSubscription)) ? (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    trackingNotCancelClick(
                                        subscriptionData,
                                        "feedback_screen_view"
                                    );
                                    history.replace(
                                        `${CONSTANTS.NAVIGATION_URL.MEMBERSHIPS}/${id}`
                                    );
                                }}
                                sx={{
                                    marginBottom: {
                                        xs: "12px",
                                        md: 0,
                                    },
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    lineHeight: "19.98px",
                                    letterSpacing: "0.01em",
                                }}
                            >
                                DON'T CANCEL
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setOpenPauseModal(true);
                                    trackingPauseInsteadClick(subscriptionData);
                                }}
                                sx={{
                                    marginBottom: {
                                        xs: "12px",
                                        md: 0,
                                    },
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    lineHeight: "19.98px",
                                    letterSpacing: "0.01em",
                                }}
                            >
                                PAUSE INSTEAD
                            </Button>
                        )}

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleContinueCancel}
                            disabled={active < 1}
                            sx={{
                                marginLeft: {
                                    xs: "0",
                                    md: "16px",
                                },
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "19.98px",
                                letterSpacing: "0.01em",
                            }}
                        >
                            CONTINUE CANCELING
                        </Button>
                    </Box>
                </Box>
            </div>
            <PauseMembershipModal
                open={openPauseModal}
                onClose={() => setOpenPauseModal(false)}
                onSubmit={handlePauseSubscription}
            />
            <MembershipCanceledModal ref={membershipCancelSuccessRef} />
        </Box>
    );
};

export default CancelReasons;
