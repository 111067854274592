import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import React from "react";
import { WorkoutHistoriesItemType } from "Screens/WorkoutHistory/Main/types";
import "./HistoryItem.scss";
import { convertedSecondToTime } from "consts/dateTime";
var dayjsLib = require('dayjs');
var utc = require('dayjs/plugin/utc')
dayjsLib.extend(utc)

type PropsType = {
    item: WorkoutHistoriesItemType;
    currentSelect: number | string | null;
    handleSelect: (id: string) => void;
    isSynced?: boolean;
    onOpenWorkoutPopup?: any;
};

const HistoryItem = (props: PropsType) => {
    const {
        item,
        currentSelect,
        handleSelect,
        // isSynced = false,
        onOpenWorkoutPopup,
    } = props;
    const useStyles: any = makeStyles(() => ({
        root: {
            "&.MuiButtonBase-root": {
                padding: 0,
                margin: "25px 0px 0 0px",
                "@media (max-width: 375px)": {
                    margin: "10px 0 0 0",
                },
            },
        },
    }));

    const classes = useStyles();
    // const { timezone } = React.useContext(UserAppContext);
    // const timeZone = timezone?.timezone;
    //@ts-ignore
    const createTime = dayjsLib.utc(
        Number(item.createTimeTzUser) * 1000,
    );
    const month = createTime?.format("MMM") || "";
    const day = createTime?.format("DD") || "";
    const year = createTime?.format("YYYY") || "";

    const isActive = item.id === currentSelect;

    const onClickItem = (id: any) => {
        handleSelect(id);
        onOpenWorkoutPopup && onOpenWorkoutPopup();
    };

    const convertedSecondToTime = (time: number, toHour?: boolean) => {
        if (toHour) {
            if (!time) return "00:00:00";
            let hour = Math.floor(time / 3600);
            let minute = Math.floor(time / 60) % 60;
            let second = Math.floor(time % 60);
            if (second === 60) {
                minute++;
                second = 0;
            }
            if (minute === 60) {
                hour++;
                minute = 0;
            }
            return [hour, minute, second]
                .map((item) => (item < 10 ? `0${item}` : String(item)))
                .join(":");
        }

        if (!time) return "00:00";
        let minute = Math.floor(time / 60);
        let second = Math.floor(time % 60);
        if (second === 60) {
            minute++;
            second = 0;
        }
        const minuteStr = minute >= 10 ? String(minute) : `0${String(minute)}`;
        const secondStr = second >= 10 ? String(second) : `0${String(second)}`;
        return `${minuteStr}:${secondStr}`;
    };

    return (
        <Button
            onClick={() => onClickItem(item.id)}
            className={classes.root + " itemHistorySectionWrapper"}
            sx={{ borderRadius: "0px !important" }}
        >
            <div className={`item-content-container ${isActive && "__active"}`}>
                {/* {isSynced ? <div className="marker"><img src="/images/strava-logo.png" width="30px"></img></div> : ""} */}
                <div className="content-container">
                    {/* <div className={`date`}>{`${month} ${day}, ${year}`}</div> */}
                    <div className={`date`}>{`${month} ${day}, ${year}`}</div>
                    <div className="wrapper-name">
                        <div
                            className="name"
                            // style={{ "-webkit-box-orient": "vertical" } as any}
                        >
                            {item.name}
                        </div>
                    </div>

                    <div className="metric-line"></div>
                    <div className="metrics">
                        <div className="metrics-item-container">
                            <div className="metric-label">TIME</div>
                            <div className="metric-number">
                                {convertedSecondToTime(Math.floor(item.activeTime) || 0)}
                            </div>
                        </div>
                        <div className="metrics-item-container">
                            <div className="metric-label">SPLIT</div>
                            <div className="metric-number">
                                {convertedSecondToTime(Math.round(item?.avgSecp500) || 0)}
                            </div>
                        </div>
                        <div className="metrics-item-container">
                            <div className="metric-label">METERS</div>
                            <div className="metric-number">
                                {item?.totalMeter?.toFixed(0) || 0}
                            </div>
                        </div>
                        <div className="metrics-item-container">
                            <div className="metric-label">OUTPUT(KJ)</div>
                            <div className="metric-number">
                                {Math.floor(item?.totalOutput || 0)}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className={`globar ${isActive ? "globar-active" : "globar-unactive"}`} /> */}
            </div>
        </Button>
    );
};
export default HistoryItem;
