import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import OlympianCard from "../OlympianCard";
import OlympianCardV2 from "../OlympianCardV2";
import WeekStreakCard from "../WeekStreakCard";
import DaysSinceLastWorkoutCard from "../DaysSinceLastWorkoutCard";
import style from "./WeekStreakHoc.module.scss";
import { gameServerApi } from "services/gameServerV2";

const formatGameUserLevel = (userLevel: any) => {
    return {
        levelProgress:userLevel?.progress || 0,
        nextLevel: {
            iconUrl: userLevel?.metadata?.next?.iconUrl,
            name: userLevel?.metadata?.next?.name || "",
            id: userLevel?.metadata?.next?.id || 0,
        },
        remainHour:userLevel?.remaining?.hour || 0,
        remainMinute: userLevel.remaining.minute,
        maxLevel: {
            isMaxLevel: userLevel?.progress === 100,
            name: userLevel?.metadata?.current?.name,
            iconUrl: userLevel?.metadata?.current?.iconUrl
        }
    }
}

const WeekStreakHoc = () => {
    const [gameUserLevel, setGameUserLevel] = useState({
        levelProgress:0,
        nextLevel: {iconUrl: '', name: "", id: 0},
        remainHour:0,
        remainMinute:0,
        maxLevel: {

        },
    });
    useEffect(()=>{
        gameServerApi.user.getUserStats().then((res) => {
            res?.data?.userLevel && setGameUserLevel(formatGameUserLevel(res?.data?.userLevel));
        });
    }, [])
    return (
        <div className={style["WeekStreakHocWrapper"]}>
            <Grid container spacing={{ xs: 2 }}>
                <Grid item sm={12} lg={4} className={style["gameLevelGrid"]}>
                    <OlympianCard gameUserInfo={gameUserLevel} />
                    {/* <OlympianCardV2 /> */}
                </Grid>
                <Grid item sm={12} lg={4} className={style["streakLevelGrid"]}>
                    <WeekStreakCard />
                </Grid>
                <Grid item sm={12} lg={4} className={style["siceWorkoutGrid"]}>
                    <DaysSinceLastWorkoutCard />
                </Grid>
            </Grid>
        </div>
    );
};

export default WeekStreakHoc;
