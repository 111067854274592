import { MONTH_NAMES } from "./monthNames";

/**
 * convert second number to fomart: hours:minutes:seconds
 * @param time
 * @param toHour true convert second number to hh:mm:ss, false: convert number to MM:ss
 */
const convertedSecondToTime = (time: number, toHour?: boolean) => {
    if (toHour) {
        if (!time) return "00:00:00";
        let hour = Math.floor(time / 3600);
        let minute = Math.floor(time / 60) % 60;
        let second = Math.round(time % 60);
        if (second === 60) {
            minute++;
            second = 0;
        }
        if (minute === 60) {
            hour++;
            minute = 0;
        }
        return [hour, minute, second]
            .map((item) => (item < 10 ? `0${item}` : String(item)))
            .join(":");
    }

    if (!time) return "00:00";
    let minute = Math.floor(time / 60);
    let second = Math.round(time % 60);
    if (second === 60) {
        minute++;
        second = 0;
    }
    const minuteStr = minute >= 10 ? String(minute) : `0${String(minute)}`;
    const secondStr = second >= 10 ? String(second) : `0${String(second)}`;
    return `${minuteStr}:${secondStr}`;
};

/**
 * round down time and convert second number to fomart: hours:minutes:seconds
 * @param time
 * @param toHour true convert second number to hh:mm:ss:, false: convert number to mm:ss
 */
const convertedSecondToTimeV2 = (time: number, toHour?: boolean) => {
    if (toHour) {
        if (!time) return "00:00:00";
        let hour = Math.floor(time / 3600);
        let minute = Math.floor(time / 60) % 60;
        let second = Math.floor(time % 60);
        if (second === 60) {
            minute++;
            second = 0;
        }
        if (minute === 60) {
            hour++;
            minute = 0;
        }
        return [hour, minute, second]
            .map((item) => (item < 10 ? `0${item}` : String(item)))
            .join(":");
    }

    if (!time) return "00:00";
    let minute = Math.floor(time / 60);
    let second = Math.floor(time % 60);
    if (second === 60) {
        minute++;
        second = 0;
    }
    const minuteStr = minute >= 10 ? String(minute) : `0${String(minute)}`;
    const secondStr = second >= 10 ? String(second) : `0${String(second)}`;
    return `${minuteStr}:${secondStr}`;
};
const DATE_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export { MONTH_NAMES, DATE_NAMES, convertedSecondToTime, convertedSecondToTimeV2 };
