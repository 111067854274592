import React, { useEffect, useRef } from "react";
import style from "./OlympianCard.module.scss";
import CardSimple from "Components/CardSimple";
import CircularProgress from "@mui/material/CircularProgress";
import GameLevelIcons from "Components/GameLevelIcons";

interface OlympianCardProps {
    gameUserInfo: {
        levelProgress: number,
        nextLevel: {iconUrl: string, name: string, id?: number},
        remainHour: number,
        remainMinute: number,
        maxLevel?: {iconUrl?: string, isMaxLevel?: boolean, name?: string},
    };
}
const OlympianCard = ({ gameUserInfo }: OlympianCardProps) => {
    const {
        levelProgress,
        nextLevel,
        remainHour,
        remainMinute,
        maxLevel,
    } = gameUserInfo;
    const circleProcessBarRef = useRef(null);
    const renderRemainingText = (hour: number, minute: number) => {
        if (hour > 0) {
            const hourText = hour === 1 ? "HOUR" : "HOURS";
            if (minute > 0) {
                return `${hour} ${hourText} & ${minute} MIN REMAINING`;
            } else {
                return `${hour} ${hourText}`;
            }
        } else if (hour === 0 && minute > 0) {
            return `${minute} MIN REMAINING`;
        } else {
            return "";
        }
    };

    const renderMaxLevelContent = () => {
        return (
            <div
                className={`${style["weekStreak"]} ${style["weekStreakMaxLevel"]}`}
            >
                <div className={style["achieve"]}>
                    <CircularProgress
                        variant="determinate"
                        value={100}
                        className={style["circleProcess"]}
                    />
                    <CircularProgress
                        ref={circleProcessBarRef}
                        variant="determinate"
                        value={100}
                        className={
                            style["circleProcess"] + " circleProcessFakeBar"
                        }
                    />
                    <GameLevelIcons iconSrc={maxLevel?.iconUrl} />
                </div>
                <div className={style["rightContent"]}>
                    <div className={style["text"]}>{maxLevel?.name || "---"}</div>
                    <div className={style["bestAchieve"]}>
                        YOU'VE REACHED THE MAX LEVEL
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (circleProcessBarRef && circleProcessBarRef.current) {
            const radient = document.getElementById("radientHiddenId");
            const svgDom =
                //@ts-ignore
                circleProcessBarRef.current.getElementsByTagName("svg")[0];
            //@ts-ignore
            svgDom.appendChild(radient);
        }
    }, [circleProcessBarRef]);

    return (
        <div className={style["OlympianCardWrapper"]}>
            <svg style={{ display: "none" }}>
                <defs id={"radientHiddenId"}>
                    <linearGradient
                        id="circleBarRadient"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="100%"
                    >
                        <stop
                            offset="11.92%"
                            style={{ stopColor: "#88EB81", stopOpacity: 1 }}
                        />
                        <stop
                            offset="86.86%"
                            style={{ stopColor: "#E3F354", stopOpacity: 1 }}
                        />
                    </linearGradient>
                </defs>
            </svg>
            <CardSimple>
                {!maxLevel?.isMaxLevel ? (
                    <div className={style["weekStreak"]}>
                        <div className={style["achieve"]}>
                            <CircularProgress
                                variant="determinate"
                                value={100}
                                className={style["circleProcess"]}
                            />
                            <CircularProgress
                                ref={circleProcessBarRef}
                                variant="determinate"
                                value={levelProgress || 0}
                                className={
                                    style["circleProcess"] +
                                    " circleProcessFakeBar"
                                }
                            />
                            <GameLevelIcons iconSrc={nextLevel.iconUrl.replace('.png', '_256x256.png')} id={nextLevel?.id || 0} />
                        </div>
                        <div className={style["rightContent"]}>
                            <div className={style["text"]}>
                                {Math.floor(levelProgress) || 0}% TO{" "}
                                {nextLevel?.name || "---"}
                            </div>
                            <div className={style["bestAchieve"]}>
                                {renderRemainingText(remainHour, remainMinute)}
                            </div>
                        </div>
                    </div>
                ) : (
                    renderMaxLevelContent()
                )}
            </CardSimple>
        </div>
    );
};

export default OlympianCard;
