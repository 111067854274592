import React from "react";
import IconLevel1 from "./icons/iconLevel1.png";
import IconLevel2 from "./icons/iconLevel2.png";
import IconLevel3 from "./icons/iconLevel3.png";
import IconLevel4 from "./icons/iconLevel4.png";
import IconLevel5 from "./icons/iconLevel5.png";
import IconLevel6 from "./icons/iconLevel6.png";
import IconLevel7 from "./icons/iconLevel7.png";
import IconLevel8 from "./icons/iconLevel8.png";
import IconLevel9 from "./icons/iconLevel9.png";
interface GameLevelIconProps {
    id?: number;
    iconSrc?: string;
}

const GameLevelIcons = ({ id, iconSrc }: GameLevelIconProps) => {
    const renderIconLevel = (id: any) => {
        if (iconSrc) {
            return <img src={iconSrc} alt={`icon Level ${id}`} />;
        }
        switch (id) {
            case 1:
                return <img src={IconLevel1} alt={"iconLevel1"} />;
            case 2:
                return <img src={IconLevel2} alt={"iconLevel2"} />;
            case 3:
                return <img src={IconLevel3} alt={"iconLevel3"} />;
            case 4:
                return <img src={IconLevel4} alt={"iconLevel4"} />;
            case 5:
                return <img src={IconLevel5} alt={"iconLevel5"} />;
            case 6:
                return <img src={IconLevel6} alt={"iconLevel6"} />;
            case 7:
                return <img src={IconLevel7} alt={"iconLevel7"} />;
            case 8:
                return <img src={IconLevel8} alt={"iconLevel8"} />;
            case 9:
                return <img src={IconLevel9} alt={"iconLevel9"} />;
            default:
                return "";
        }
    };
    return <>{renderIconLevel(id)}</>;
};

export default GameLevelIcons;
