import React, { useEffect, useLayoutEffect, useState, memo } from "react";
import ApexCharts from "apexcharts";
import "./VerticalBarChart.scss";
import { CONSTANTS } from "consts";

const colorColumnBars = ["#F5490F", "#F57D0F", "#939393"];
interface VerticalBarChartProps {
    data: Array<number>;
    yData?: any;
    xData?: any;
    nameChart?: any;
    keyChart?: 'outputs' | 'times' | 'metters' | string
    // initData:any
    // activeTab: any
}
const VerticalBarChart = ({
    data,
    yData,
    xData,
    nameChart,
    keyChart
    // initData,
    // activeTab,
}: VerticalBarChartProps) => {
    const [chart, setChart] = useState();
    const maxValueChart = Math.max(...data);
    const isAPKBikerMachine =
        Number(localStorage?.getItem("appId") || 0) ===
        CONSTANTS.SWITCH_MACHINE.bike.id;
    
    console.log('vmcmcmcmcmccm', {data, yData, xData, keyChart});
  
    const getMaxValueLevel = () => {
        if(maxValueChart <= 4) {
            return 4
        } else {
            const tempString = parseInt(String(maxValueChart));
            const lengthValue = String(tempString).length;
            const maxTemp = 1 * Number(`1e${lengthValue - 2}`);
            if(maxValueChart >20) {
                for (let i = 4; ;) {
                    // const numberStep = i * 5;
                    if (
                        i > maxValueChart &&
                        (i / 4) % 4 === 0 &&
                        (i/4) % maxTemp === 0
                    ) {
                        return i;
                    }
                    i=i + 4
                }
            } else {
                for (let i = 4; ;) {
                    const numberStep = i + 4;
                    if (
                        numberStep > maxValueChart &&
                        (numberStep/4) % 4 === 0
                    ) {
                        return numberStep;
                    }
                    i=i + 4
                }
            }
        }
    }

    const getValueTooltip = (value: number) => {
        if(keyChart === 'outputs') {
            return Math.floor(value)
        } else if(keyChart === 'times') {
            return Math.floor(value)
        } else if(keyChart === 'metters') {
            if(isAPKBikerMachine) {
                return Math.floor(value *100)/100;
            } else {
                return Math.round(value);
            }
        }
    }
    const options = {
        series: [
            {
                name: nameChart,
                data: data,
            },
        ],
        chart: {
            type: "bar",
            height: "100%",
            id: "workoutBarChart",
            zoom: {
                enabled: false,
            },
            events: {
                beforeMount: function (chartContext: any, config: any) {
                    //@ts-ignore
                    const oldChartElement =
                        document.getElementById("VerticalBarChart")?.firstChild;
                    if (oldChartElement) {
                        oldChartElement.remove();
                    }
                },
                mounted: function (chartContext: any, config: any) {
                    const chartElement = document.getElementById(
                        "apexchartsworkoutBarChart"
                    );
                    const barAreaElements =
                        chartElement?.getElementsByClassName(
                            "apexcharts-bar-area"
                        );
                    if (barAreaElements && chartElement) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i] !== maxValueChart) {
                                //@ts-ignore
                                barAreaElements[i].style.fill =
                                    colorColumnBars[2];
                            }
                        }
                    }
                },
                updated: function (chartContext: any, config: any) {
                    const chartElement = document.getElementById(
                        "apexchartsworkoutBarChart"
                    );
                    const barAreaElements =
                        chartElement?.getElementsByClassName(
                            "apexcharts-bar-area"
                        );
                    if (barAreaElements && chartElement) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i] !== maxValueChart) {
                                //@ts-ignore
                                barAreaElements[i].style.fill =
                                    colorColumnBars[2];
                            }
                        }
                    }
                },
            },
            animations: {
                enabled: true,
                easing: "easeinout",
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 500,
                },
            },
        },
        colors: [
            function ({ value, seriesIndex, w }: any) {
                if (value < maxValueChart) {
                    return colorColumnBars[2];
                } else {
                    return colorColumnBars[0];
                }
            },
        ],
        fill: {
            colors: [
                function ({ value, seriesIndex, w }: any) {
                    if (value < maxValueChart) {
                        return colorColumnBars[2];
                    } else {
                        return colorColumnBars[0];
                    }
                },
            ],
            type: "gradient",
            gradient: {
                type: "vertical",
                shadeIntensity: 1,
                opacityFrom: 1,
                opacityTo: 1,
                colorStops: [
                    {
                        offset: 20,
                        color: colorColumnBars[1],
                        opacity: 1,
                    },
                    {
                        offset: 60,
                        color: colorColumnBars[0],
                        opacity: 1,
                    },
                    {
                        offset: 95,
                        color: colorColumnBars[0],
                        opacity: 1,
                    },
                ],
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "70%",
                endingShape: "rounded",
                className: "barColumns",
                colors: {
                    ranges: [
                        {
                            from: 0,
                            to: 800,
                            color: "#ccc",
                        },
                    ],
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
            curve: "smooth",
        },
        xaxis: {
            categories: xData,
            labels: {
                style: {
                    color: "#939393",
                    fontSize: "16px",
                    fontFamily: "Work Sans",
                    cssClass: "apexcharts-xaxis-title",
                },
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            title: {
                text: "",
            },
            decimalsInFloat: 0,
            max: maxValueChart <= 4 ? 4 : getMaxValueLevel(),
            tickAmount: 4,
            min: 0,
            labels: {
                show: true,
                align: "right",
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: [],
                    fontSize: "16px",
                    lineHeight: "16px",
                    fontFamily: "MonoSpec",
                    cssClass: "apexcharts-yaxis-label",
                },
                formatter: function (value: any) {
                    // console.log({value, type: typeof value})
                    if (value !== Infinity) {
                        return value.toFixed(0);
                    } else {
                        return value.toFixed(0);
                    }
                },
            },
            axisBorder: {
                show: true,
                color: "#E6E6E6",
                offsetX: 0,
                offsetY: 0,
            },
        },
        grid: {
            show: true,
            borderColor: "#e6e6e6",
            strokeDashArray: 0,
            position: "back",
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return getValueTooltip(Number(val));
                },
            },
        },
        responsive: [
            {
                breakpoint: 500,
                minWidth: 50,
                options: {
                    chart: {
                        height: 395,
                    },
                    yaxis: {
                        decimalsInFloat: 0,
                        max: maxValueChart <= 4 ? 4 : getMaxValueLevel(),
                        tickAmount: 4,
                        min: 0,
                        labels: {
                            show: true,
                            style: {
                                colors: [],
                                // fontSize: "18px",
                                // lineHeight: "16px",
                                fontFamily: "MonoSpec",
                                cssClass: "apexcharts-mobile-yaxis-label",
                            },
                            formatter: function (value: any) {
                                if (value >= 1000) {
                                    return (
                                        (value / 1000)
                                            .toFixed(1)
                                            .replace(".00", "")
                                            .replace(".0", "") + "K"
                                    );
                                } else {
                                    return value.toFixed(0).replace(".00", "");
                                }
                            },
                        },
                        axisBorder: {
                            show: true,
                            color: "#E6E6E6",
                            offsetX: 0,
                            offsetY: 0,
                        },
                    },
                },
            },
        ],
    };
    useLayoutEffect(() => {
        setChart(
            //@ts-ignore
            new ApexCharts(document.querySelector("#VerticalBarChart"), options)
        );
    }, []);
    useEffect(() => {
        if (chart) {
            chart &&
                //@ts-ignore
                chart.render().then(() => {
                    //@ts-ignore
                    chart.resetSeries();
                });
            const domChartEl = document.getElementById("workoutBarChart");
            if (domChartEl) {
                //@ts-ignore
                chart.resetSeries();
            }
        }
    }, [chart]);
    useEffect(() => {
        if (
            data &&
            data.length > 0 &&
            yData &&
            xData &&
            nameChart &&
            chart
        ) {
            //@ts-ignore
            chart?.resetSeries();
            //@ts-ignore
            chart?.updateOptions(options, true, true, true);
        }
    }, [data, chart]);

    return (
        <div className={"VerticalBarChartWrapper"}>
            <div className="chartYTitle">{yData?.label}</div>
            <div id={"VerticalBarChart"}></div>
        </div>
    );
};

export default memo(VerticalBarChart);
