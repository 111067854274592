import { useEffect, useState } from "react";
import { gameServerApi } from "services/gameServerV2";

const useGameUserLevelHook = () => {
    const [gameUserLevel, setGameUserInfo] = useState<any>(null);

    useEffect(() => {
        gameServerApi.user
        .getUserStats()
        .then((res) => {
            if(res && res.status === 200) {
                //@ts-ignore
                setGameUserInfo(res.data?.userLevel);
            } else {
                setGameUserInfo(null);
            }
        })
        .catch((err) => {
            console.log(err);
            setGameUserInfo(null);
        });
    }, []);

    return {
        gameUserLevel,
    };
};
export default useGameUserLevelHook;
